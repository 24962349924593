import React from "react";
import "../reset.css";
import "./Share.js";
import Header from "./Header";
import Footer from "./Footer";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 */
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.setState({ key: Math.random() });
  }

  render() {
    return (
      <html lang="en">
        <article>
          <div
            style={{
              margin: "auto",
              maxWidth: "47.5rem",
              padding: "1.5rem 1.45rem",
              fontSize: "1.1rem",
              lineHeight: "34px",
              letterSpacing: "0.9px"
            }}
          >
            <Header siteTitle={this.props.title} />
            {this.props.children}
            <Footer />
          </div>
        </article>
      </html>
    );
  }
}

export default Layout;
