import React, { Component } from "react";
import Navigation from "./Navigation";
import NavigationMobile from "./NavigationMobile";

class NavigationWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
      windowHeight: 0
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

    this.setState({ windowWidth, windowHeight });
  }

  render() {
    return (
      <div>
        {this.state.windowWidth < 590 ? (
          <NavigationMobile siteTitle={this.props.siteTitle} />
        ) : (
          <Navigation siteTitle={this.props.siteTitle} />
        )}
      </div>
    );
  }
}

export default NavigationWrapper;
