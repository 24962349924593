import React from "react";

import NavigationWrapper from "./NavigationWrapper";
/**
 * Header component
 *
 * The Header component covers the persistent navigation
 * and site title that show up on each page.
 **/
const Header = ({ siteTitle }) => {
  return (
    <header>
      <div>
        <NavigationWrapper siteTitle={siteTitle} />
      </div>
    </header>
  );
};
export default Header;
