/**
 * Constants
 *
 * Could also be labeled data, this file contains set parameters that
 * I don't want to recode when repeated. For instance, slugs and reading links.
 */

export const MEDIA = {
  mobile: "@media (min-width: 600px)"
};

export const LINKS = {
  about: {
    href: "/about",
    text: "About"
  },
  blog: {
    href: "/blog",
    text: "Blog"
  },
  newsletter: {
    href: "/newsletter",
    text: "Newsletter"
  }
};

export const SOCIAL = {
  twitter: {
    href: "https://twitter.com/cdeweyx",
    target: "_blank",
    title: "Twitter"
  },
  linkedin: {
    href: "https://www.linkedin.com/in/conordewey/",
    target: "_blank",
    title: "LinkedIn"
  },
  insta: {
    href: "https://www.instagram.com/cdeweyx/",
    target: "_blank",
    title: "Instagram"
  },
  github: {
    href: "https://github.com/cdeweyx",
    target: "_blank",
    title: "Github"
  }
};

export const READING = {
  selfImprovement: [
    {
      href: "https://www.amazon.com/dp/B07D23CFGR/",
      title: "Atomic Habits: An Easy & Proven Way to Build Good Habits"
    },
    {
      href: "https://www.amazon.com/dp/B004P1JDJO/",
      title: "Drive: The Surprising Truth About What Motivates Us"
    },
    {
      href: "https://www.amazon.com/dp/B00GEEB9YC/",
      title: "Waking Up: A Guide to Spirituality Without Religion"
    },
    {
      href: "https://www.amazon.com//dp/B005GFBNSW/",
      title: "The Way to Love: Meditations for Life"
    },
    {
      href: "https://www.amazon.com/dp/B071CTK28D/",
      title: "Principles: Life and Work"
    },
    {
      href: "https://www.amazon.com/dp/B00555X8OA/",
      title: "Thinking, Fast and Slow"
    },
    {
      href:
        "https://medium.com/personal-growth/travel-is-no-cure-for-the-mind-e449d3109d71",
      title: "Travel Is No Cure for the Mind"
    },
    {
      href: "https://sivers.org/slow",
      title: "I’m A Very Slow Thinker"
    },
    {
      href: "https://m.signalvnoise.com/ive-never-had-a-goal/",
      title: "I've Never Had a Goal"
    },
    {
      href: "https://www.amazon.com/dp/B002WE46UW/",
      title: "The 4-Hour Workweek"
    },
    {
      href: "https://www.amazon.com/dp/B071KJ7PTB/",
      title: "Tribe of Mentors: Short Life Advice from the Best in the World"
    },
    {
      href:
        "https://www.amazon.com/Mental-Game-Baseball-Guide-Performance-ebook/dp/B009R6GJDA/",
      title: "The Mental Game of Baseball: A Guide to Peak Performance"
    }
  ],
  career: [
    {
      href: "https://www.amazon.com/dp/B000QCSA54/",
      title: "The Dip: A Little Book That Teaches You When to Quit"
    },
    {
      href: "http://www.paulgraham.com/makersschedule.html",
      title: "Maker's Schedule, Manager's Schedule"
    },
    {
      href: "https://blog.samaltman.com/how-to-be-successful",
      title: "How to Be Successful"
    },
    {
      href: "http://www.paulgraham.com/love.html",
      title: "How to Do What You Love"
    },
    {
      href: "https://boz.com/articles/career-cold-start",
      title: "A Career Cold Start Algorithm"
    },
    {
      href: "https://pmarchive.com/guide_to_career_planning_part1.html",
      title: "Marc Andreessen's Guide to Career Planning"
    },
    {
      href: "http://paulgraham.com/ecw.html",
      title: "How to Be an Expert in a Changing World"
    },
    {
      href:
        "https://medium.com/the-year-of-the-looking-glass/how-to-think-about-your-career-abf5300eba08",
      title: "How to Think About Your Career"
    }
  ],
  business: [
    {
      href: "https://www.amazon.com/dp/B004J4XGN6/",
      title: "The Lean Startup"
    },
    {
      href: "https://www.amazon.com/dp/B00J6YBOFQ/",
      title: "Zero to One: Notes on Startups"
    },
    {
      href:
        "https://www.amazon.com/Blue-Ocean-Strategy-Uncontested-Competition/dp/1591396190",
      title: "Blue Ocean Strategy: How to Create Uncontested Market Space"
    },
    {
      href: "https://stratechery.com/2015/aggregation-theory/",
      title: "Aggregation Theory"
    },
    {
      href: "https://www.eugenewei.com/blog/2018/5/21/invisible-asymptotes",
      title: "Invisible Asymptotes"
    },
    {
      href: "https://www.joelonsoftware.com/2002/06/12/strategy-letter-v/",
      title: "Strategy Letter V"
    },
    {
      href:
        "https://hbr.org/2014/06/how-to-succeed-in-business-by-bundling-and-unbundling",
      title: "How to Succeed in Business by Bundling and Unbundling"
    },
    {
      href: "https://andrewchen.co/how-to-build-a-growth-team/",
      title: "How to Build a Growth Team"
    },
    {
      href:
        "https://firstround.com/review/indispensable-growth-frameworks-from-my-years-at-facebook-twitter-and-wealthfront/",
      title: "Indispensable Growth Frameworks from My Years at Facebook"
    },
    {
      href: "https://andrewchen.co/how-to-build-a-growth-team/",
      title: "5 Ways to Build a $100 Million Business "
    }
  ],
  product: [
    {
      href:
        "https://www.amazon.com/Mom-Test-customers-business-everyone/dp/1492180742",
      title: "The Mom Test: How to Talk to Customers"
    },
    {
      href: "https://a16z.com/2017/02/18/12-things-about-product-market-fit/",
      title: "12 Things About Product-Market Fit"
    },
    {
      href:
        "https://medium.com/the-year-of-the-looking-glass/building-products-91aa93bea4bb",
      title: "Building Products"
    },
    {
      href:
        "https://jacks.tumblr.com/post/33785796042/lets-reconsider-our-users",
      title: "Let's Reconsider Our Users"
    },
    {
      href: "https://seths.blog/2016/04/the-choke-points/",
      title: "The Choke Points"
    },
    {
      href:
        "https://firstround.com/review/how-superhuman-built-an-engine-to-find-product-market-fit/",
      title: "How Superhuman Built an Engine to Find Product-Market Fit"
    }
  ],
  education: [
    {
      href:
        "https://www.amazon.com/Poor-Charlies-Almanack-Charles-Expanded/dp/1578645018",
      title: "Poor Charlie's Almanack: The Wit and Wisdom of Charlie Munger"
    },
    {
      href:
        "https://25iq.com/2015/08/22/a-dozen-things-ive-learned-from-charlie-munger-about-mental-models-and-worldly-wisdom/",
      title: "A Dozen Things I’ve Learned from Charlie Munger"
    },
    {
      href: "https://www.amazon.com/dp/B000QCQ970/",
      title: "The Art of Learning: An Inner Journey to Optimal Performance"
    },
    {
      href: "https://www.amazon.com/dp/B00RKO6MS8/",
      title: "Superforecasting: The Art and Science of Prediction"
    },
    {
      href:
        "https://www.firstthings.com/article/2018/05/letter-to-an-aspiring-intellectual",
      title: "Letter to an Aspiring Intellectual"
    },
    {
      href:
        "https://medium.com/@davidventuri/i-dropped-out-of-school-to-create-my-own-data-science-master-s-here-s-my-curriculum-1b400dcee412",
      title: "I Dropped Out to Create My Own Data Science Master’s"
    }
  ],
  writing: [
    {
      href: "https://www.amazon.com/dp/B0090RVGW0/",
      title: "On Writing Well: An Informal Guide to Writing Nonfiction"
    },
    {
      href: "https://pjrvs.com/rats",
      title: "Find Your Rat People"
    },
    {
      href: "https://www.amazon.com/dp/B007A4SDCG/",
      title: "The War of Art"
    },
    {
      href:
        "https://www.brainpickings.org/2012/12/27/chuck-close-on-creativity/",
      title: "Chuck Close on Creativity and Work Ethic"
    }
  ],
  entrepreneurship: [
    {
      href: "https://kk.org/thetechnium/1000-true-fans/",
      title: "1,000 True Fans"
    },
    {
      href: "http://www.paulgraham.com/startupideas.html",
      title: "How to Get Startup Ideas"
    },
    {
      href: "http://paulgraham.com/founders.html",
      title: "What We Look for in Founders"
    },
    {
      href: "https://www.scottadamssays.com/2010/06/04/the-value-of-ideas/",
      title: "The Value of Ideas"
    }
  ],
  tech: [
    {
      href: "http://blogoscoped.com/archive/2005-08-24-n14.html",
      title: "Why Good Programmers Are Lazy and Dumb"
    },
    {
      href:
        "https://simplystatistics.org/2018/06/04/trustworthy-data-analysis/",
      title: "Trustworthy Data Analysis"
    },
    {
      href: "https://www.amazon.com/dp/B0769XXGXX",
      title: "Valley of Genius: The Uncensored History of Silicon Valley"
    }
  ]
};
