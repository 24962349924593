import React from "react";
import { Block, Row } from "glamor/jsxstyle";
import { FaTwitter, FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
import { Link } from "gatsby";
import { SOCIAL } from "../constants";
/** import NewsletterForm from "../components/NewsletterForm"; **/

/**
 * Footer component
 *
 * The Fooer component covers the persistent bottom
 * content including links and copyright info.
 **/

const Footer = () => (
  <>
    {" "}
    {/*
    <Block>
      <div className="footerHeader">Subscribe</div>
    </Block>
    <section className="footerContent">
      I send an email newsletter each week with the links I thought were
      interesting, plus any new posts or projects from me. There are{" "}
      <strong>1,500+ subscribers</strong>. You should sign up too.
    </section>
    <NewsletterForm />  */}
    <Block>
      <Row
        paddingBottom="0.3rem"
        /** paddingTop="1.6rem" **/
        paddingTop="5.2rem"
        /**marginBottom="-0.5rem" **/
        marginBottom="0rem"
        justifyContent="center"
        alignItems="center"
      >
        <Block
          fontWeight="700"
          textDecoration="none"
          fontSize="17px"
          letterSpacing="1.3px"
          to="/"
        >
          <div
            className="footerTrademark"
            style={{
              boxShadow: "none",
              textDecoration: "none",
              fontWeight: "500",
              color: "#A8ADB2",
              lineHeight: "1.5rem"
            }}
            to={`/`}
          >
            <Link to="">{"© Conor Dewey"}</Link>
          </div>
        </Block>
        <Row
          component="nav"
          flex="1"
          display="flex"
          maxWidth="500px"
          fontSize="18px"
          maxWidth="36rem"
          alignItems="center"
          justifyContent="flex-end"
          marginTop="12px"
          className="footerLogos"
        >
          <a
            style={{
              boxShadow: "none",
              marginLeft: "1rem"
            }}
            href={SOCIAL.twitter.href}
            target="_blank"
            rel="noopener noreferrer"
            className="heroSocialTwitter"
          >
            <FaTwitter size={22} title={"Twitter"} />
          </a>
          <a
            style={{
              boxShadow: "none",
              marginLeft: "1rem"
            }}
            href={SOCIAL.insta.href}
            target="_blank"
            rel="noopener noreferrer"
            className="heroSocialInstagram"
          >
            <FaInstagram size={22} title={"Instagram"} />
          </a>
          <a
            style={{
              boxShadow: "none",
              marginLeft: "1rem"
            }}
            href={SOCIAL.linkedin.href}
            target="_blank"
            rel="noopener noreferrer"
            className="heroSocialLinkedIn"
          >
            <FaLinkedin size={22} title={"LinkedIn"} />
          </a>
          <a
            style={{
              boxShadow: "none",
              marginLeft: "1rem"
            }}
            href={SOCIAL.github.href}
            target="_blank"
            rel="noopener noreferrer"
            className="heroSocialGithub"
          >
            <FaGithub size={22} title={"Github"} />
          </a>
        </Row>
      </Row>
    </Block>
  </>
);

export default Footer;
