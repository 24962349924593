import React from "react";
import { Link } from "gatsby";
import { Row, Block } from "glamor/jsxstyle";

import { LINKS } from "../constants";

/**
 * Main navigation component
 *
 * The navigation component handles the links at the top of the page.
 * These are persistent across layouts and will reformat automatically when
 * the screen gets too small.
 **/
const Navigation = ({ siteTitle }) => {
  return (
    <Block
      position="relative"
      top="0px"
      right="0px"
      left="0px"
      width="100%"
      paddingTop="1rem"
      paddingBottom="2.5rem"
    >
      <Row justifyContent="center" alignItems="center">
        <Block
          fontWeight="700"
          textDecoration="none"
          textTransform="uppercase"
          fontSize="18px"
          letterSpacing="1.9px"
        >
          <Link
            className="siteTitle"
            style={{
              boxShadow: "none",
              textDecoration: "none",
              fontWeight: "700",
              textAlign: "center",
              lineHeight: "0.5rem",
              fontFamily: "'system-ui', 'Roboto', 'Helvetica Neue', sans-serif",
              letterSpacing: "0.1em"
            }}
            to={`/`}
          >
            {siteTitle}
          </Link>
        </Block>
        <Row
          className="navLink"
          component="nav"
          flex="1"
          display="flex"
          maxWidth="500px"
          fontSize="18px"
          maxWidth="34rem"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Link
            style={{
              boxShadow: "none",
              marginLeft: "1.4rem"
            }}
            to={LINKS.about.href}
          >
            {LINKS.about.text}
          </Link>
          <Link
            style={{
              boxShadow: "none",
              marginLeft: "1.4rem"
            }}
            to={LINKS.blog.href}
          >
            {LINKS.blog.text}
          </Link>
          <a
            style={{
              boxShadow: "none",
              marginLeft: "1.4rem"
            }}
            href="https://conor.substack.com/welcome"
            target="_blank"
            rel="noopener noreferrer"
          >
            {LINKS.newsletter.text}
          </a>
        </Row>
      </Row>
    </Block>
  );
};
export default Navigation;
