import React from "react";
import { Link } from "gatsby";
import { Row, Block } from "glamor/jsxstyle";
import { LINKS } from "../constants";
import "../reset.css";

/**
 * Main navigation component
 *
 * The navigation component handles the links at the top of the page.
 * These are persistent across layouts and will reformat automatically when
 * the screen gets too small.
 **/

class NavigationMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    });
  }

  render() {
    return (
      <>
        {this.state.isMenuOpen ? (
          <>
            <Block
              position="absolute"
              padding="0"
              margin="0"
              left="0px"
              width="100%"
              height="100%"
              zIndex="1000"
            >
              <Block
                fontWeight="700"
                fontSize="26px"
                letterSpacing="1.8px"
                paddingTop="2.5rem"
                to="/"
                alignItems="left"
                maxWidth="40px"
                className="openNavLinks"
              >
                <div style={{ marginBottom: "1.2rem" }}>
                  <Link
                    style={{
                      boxShadow: "none",
                      marginLeft: "1.1rem"
                    }}
                    to={LINKS.about.href}
                  >
                    {LINKS.about.text}
                  </Link>
                </div>
                <div style={{ marginBottom: "1.2rem" }}>
                  <Link
                    style={{
                      boxShadow: "none",
                      marginLeft: "1.1rem"
                    }}
                    to={LINKS.blog.href}
                  >
                    {LINKS.blog.text}
                  </Link>
                </div>
                <div style={{ marginBottom: "1.2rem" }}>
                  <a
                    style={{
                      boxShadow: "none",
                      marginLeft: "1.1rem"
                    }}
                    href="https://conor.substack.com/welcome"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {LINKS.newsletter.text}
                  </a>
                </div>
              </Block>
            </Block>

            <Block
              component="nav"
              flex="1"
              display="flex"
              maxWidth="500px"
              fontSize="18px"
              alignItems="center"
              justifyContent="flex-end"
              cursor="pointer"
            >
              <div
                class="container"
                class="change"
                onClick={this.handleClick}
                style={{ zIndex: "10000" }}
              >
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
              </div>
            </Block>
          </>
        ) : (
          <Block
            position="relative"
            top="-.5rem"
            right="0px"
            left="0px"
            width="100%"
            paddingBottom="0rem"
          >
            <Row justifyContent="center" alignItems="center">
              <Block
                fontWeight="700"
                textDecoration="none"
                textTransform="uppercase"
                fontSize="1.05rem"
                letterSpacing="1.9px"
                to="/"
              >
                <Link
                  style={{
                    boxShadow: "none",
                    textDecoration: "none",
                    fontWeight: "700",
                    textAlign: "center",
                    fontFamily:
                      "'system-ui', 'Roboto', 'Helvetica Neue', sans-serif",
                    letterSpacing: "0.1em",
                    className: "siteTitle",
                    color: "#A8ADB2"
                  }}
                  to={`/`}
                >
                  {this.props.siteTitle}
                </Link>
              </Block>
              <Block
                component="nav"
                flex="1"
                display="flex"
                maxWidth="500px"
                fontSize="18px"
                maxWidth="34rem"
                alignItems="center"
                justifyContent="flex-end"
                cursor="pointer"
                onClick={this.handleClick}
              >
                <div
                  class="container"
                  class=""
                  onClick={this.handleClick}
                  style={{ zIndex: "10000" }}
                >
                  <div class="bar1"></div>
                  <div class="bar2"></div>
                  <div class="bar3"></div>
                </div>
              </Block>
            </Row>
          </Block>
        )}
      </>
    );
  }
}
export default NavigationMobile;
